import React from 'react';
import { ReactComponent as NoItemsYet } from 'images/svgs/noItemsYet.svg';
import { ReactComponent as EmptyJots} from 'images/svgs/empty_jots.svg';
import {ReactComponent as UploadIcon} from 'images/svgs/upload-here-icon.svg'
import { Translate } from 'utility/helpers';
import 'styles/components/noItems.scss';

function NoItems({
  messageFrom,
  heightFrom,
  positionfrom,
  topfrom,
  paddingtopFrom,
  className = '',
  renderDescription = true,
  children = null,
  isFromTrash = false,
  isAllEmpty = false,
  isFromJots=false,
}) {
  return (
    <div
      className={`no-items-yet ${className}`}
      style={{
        position: positionfrom,
        top: topfrom,
        minHeight: heightFrom,
        paddingTop: paddingtopFrom,
      }}
    >
        { isFromJots? <EmptyJots/>: (messageFrom ==='Capture')? <UploadIcon />:<NoItemsYet />}
      {!isFromJots && renderDescription && (
        <div className={`no-items-description ${messageFrom ==='Capture' ?'capture-noitem-description':''}`}>
          <Translate data={messageFrom}>
            {isAllEmpty? 'all_empty_items':(messageFrom ==='Capture') ? 'upload_here':!isFromTrash ? 'empty_items' : 'trash_empty_items'}
          </Translate>
        </div>
      )}
      {children}
    </div>
  );
}

export default NoItems;
