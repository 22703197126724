import { store } from '../../redux/store';
import commonActions from '../../redux/common/actions';
import { getProjectDetails } from '../../utility/helpers';
import { defaultOrderBy } from '../constants';
import {useEffect, useState} from "react";

export function moveModalUtility(visibility = false) {
  const { createModalButtonsDisable } = store.getState().commonReducer;
  store.dispatch({
    type: commonActions.MOVE_MODAL_STATE,
    isFromMoveModal: visibility,
  });
  if (createModalButtonsDisable) {
    disableFolderModalButtons();
  }
}

export function disableFolderModalButtons(disable = false) {
  store.dispatch({
    type: commonActions.CREATE_MODAL_BUTTON_DISABLE,
    createModalButtonsDisable: disable,
  });
}

export function getNodeDetailsForDocument(currentNodeId) {
  const { addBarDetails } = store.getState().addBar;
  const { jotsProjectItems } = store.getState().jotsReducer;
  const { boardDetails } = store.getState().BoardGroups;
  if (addBarDetails.isFromJots) {
    return jotsProjectItems?.documents?.find(
      (data) => data?.node_id === currentNodeId,
    );
  }
  return boardDetails?.documents?.find(
    (data) => data?.node_id === currentNodeId,
  );
}

export function checkIsJotsActivity(projectId) {
  const { jotsProjectItems } = store.getState().jotsReducer;
  return jotsProjectItems?.documents?.some(
    (data) => data.project_id === projectId,
  );
}

export function getOrderByFilter(id) {
  const sortBy = id === 'jots' ? (localStorage.getItem('jotsFilter') ? localStorage.getItem('jotsFilter'):'free_space' ):(getProjectDetails()?.[id] ? 'newest_first': defaultOrderBy);
  store.dispatch({
    type: commonActions.SET_FILTER,
    sortBy,
    filterId: id,
  });
  return sortBy;
}

// Utility function to calculate DocumentItemViewState
export function documentItemViewState(data = {}) {
  const { documentItemView, nodeId, itemId } = data;
  const node = documentItemView?.[nodeId];
  if (!node) return false;

  const isCollapse = node.isCollapse;
  const isMixed = node.isMixed;
  const isIndividual = node.isIndividual;

  if (!isIndividual) {
    const mixedStateView =
      isMixed && node.mixedState?.hasOwnProperty(itemId)
        ? node.mixedState[itemId]
        : isCollapse;
    return mixedStateView;
  }

  const individualStateView =
    isIndividual && node.individual?.hasOwnProperty(itemId)
      ? node.individual[itemId]
      : isCollapse;

  return individualStateView;
}
export function getApiDetails(type, item) {
  let typeId, location, parentId, itemDetails;
  switch (type) {
    case 'group':
    case 'groups':
      typeId = item?.group_id;
      location = type;
      break;
    case 'board':
    case 'boards':
      typeId = item?.project_id;
      location = 'projects';
      itemDetails = { ...item, translate: 'board' };
      break;
    case 'folder':
    case 'folders':
    case 'document':
    case 'documents':
      typeId = item?.node_id;
      location = 'nodes';
      parentId = item?.project_id;
      itemDetails = {
        ...item,
        translate: item?.object_type === 'page' ? 'document' : 'folder',
      };
      break;
    default:
      break;
  }
  return { typeId, location, parentId, itemDetails };
}

export function useWindowResizer() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenWidth;
}