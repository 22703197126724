const actions = {
  GET_HOME_PROJECT_DETAILS: 'GET_HOME_PROJECT_DETAILS',
  GET_HOME_PROJECT_DETAILS_SUCCESS: 'GET_HOME_PROJECT_DETAILS_SUCCESS',
  DUPLICATE_JOTS_NODE_OR_SPARK: 'DUPLICATE_JOTS_NODE_OR_SPARK',
  DUPLICATE_JOTS_NODE_OR_SPARK_SUCCESS: 'DUPLICATE_JOTS_NODE_OR_SPARK_SUCCESS',
  JOTS_LOADER: 'JOTS_LOADER',
  JOTS_CONTAINER_ARCHIVE: 'JOTS_CONTAINER_ARCHIVE',
  DELETE_JOTS_DOCUMENT: 'DELETE_JOTS_DOCUMENT',
  UPDATE_DELETE_HOME_NODE_MODAL_VISIBILITY:
    'UPDATE_DELETE_HOME_NODE_MODAL_VISIBILITY',
  DELETE_MODAL_VISIBILITY: 'DELETE_MODAL_VISIBILITY',
  DUPLICATE_JOTS_INDIVIDUAL_ITEMS: 'DUPLICATE_JOTS_INDIVIDUAL_ITEMS',
  DELETE_JOTS_INDIVIDUAL_ITEMS: 'DELETE_JOTS_INDIVIDUAL_ITEMS',
  UPDATE_JOTS_DOCUMENT_ITEMS: 'UPDATE_JOTS_DOCUMENT_ITEMS',
  UPDATE_JOTS_DOCUMENT_ITEMS_MULTIPLE_DND: 'UPDATE_JOTS_DOCUMENT_ITEMS_MULTIPLE_DND',
  REMOVE_JOTS_DRAG_ITEM_FROM_DOCUMENT: 'REMOVE_JOTS_DRAG_ITEM_FROM_DOCUMENT',
  REMOVE_JOTS_INDIVIDUAL_DRAG_ITEM: 'REMOVE_JOTS_INDIVIDUAL_DRAG_ITEM',
  EDIT_JOTS_INDIVIDUAL_ITEM_FROM_CONTEXT_MENUS:
    'EDIT_JOTS_INDIVIDUAL_ITEM_FROM_CONTEXT_MENUS',
  SET_JOTS_DOCUMENT_VIEW: 'SET_JOTS_DOCUMENT_VIEW',
  ADD_JOTS_DOCUMENT_ITEM_FROM_ADD_BAR: 'ADD_JOTS_DOCUMENT_ITEM_FROM_ADD_BAR',
  ADD_JOTS_DOCUMENT_ITEM_SUCCESS: 'ADD_JOTS_DOCUMENT_ITEM_SUCCESS',
  ADD_JOTS_INDIVIDUAL_ITEM_FROM_ADD_BAR:
    'ADD_JOTS_INDIVIDUAL_ITEM_FROM_ADD_BAR',
  ADD_JOTS_INDIVIDUAL_ITEM_SUCCESS: 'ADD_JOTS_INDIVIDUAL_ITEM_SUCCESS',
  ADD_JOTS_INDIVIDUAL_ITEM_FROM_ADD_BAR_SUCCESS:
    'ADD_JOTS_INDIVIDUAL_ITEM_FROM_ADD_BAR_SUCCESS',
  EDIT_JOTS_INDIVIDUAL_ITEM_FROM_CONTEXT_MENU:
    'EDIT_JOTS_INDIVIDUAL_ITEM_FROM_CONTEXT_MENU',
  EDIT_JOTS_DOCUMENT_ITEMS_FROM_CONTEXT_MENU:
    'EDIT_JOTS_DOCUMENT_ITEMS_FROM_CONTEXT_MENU',
  TOGGLE_JOTS_HEADER_EXPAND_COLLAPSE: 'TOGGLE_JOTS_HEADER_EXPAND_COLLAPSE',
  DUPLICATE_JOTS_INDIVIDUAL_ITEM: 'DUPLICATE_JOTS_INDIVIDUAL_ITEM',
  DUPLICATE_JOTS_INDIVIDUAL_ITEM_SUCCESS:
    'DUPLICATE_JOTS_INDIVIDUAL_ITEM_SUCCESS',
  UPDATE_JOTS_DOCUMENT_PROPERTIES: 'UPDATE_JOTS_DOCUMENT_PROPERTIES',
  SHOW_JOTS_PROPERTY_MODAL: 'SHOW_JOTS_PROPERTY_MODAL',
  CLOSE_JOTS_PROPERTY_MODAL: 'CLOSE_JOTS_PROPERTY_MODAL',
  CREATE_JOTS_DOCUMENT_SUCCESS: 'CREATE_JOTS_DOCUMENT_SUCCESS',
  UPDATE_JOTS_DOCUMENT: 'UPDATE_JOTS_DOCUMENT',
  DUPLICATE_JOTS_DOCUMENT_ITEM_API_SUCCESS:
    'DUPLICATE_JOTS_DOCUMENT_ITEM_API_SUCCESS',
  UPDATE_JOTS_INDIVIDUAL_ITEMS: 'UPDATE_JOTS_INDIVIDUAL_ITEMS',
  UPDATE_JOTS_INDIVIDUAL_ITEMS_MULTIPLE_DND: 'UPDATE_JOTS_INDIVIDUAL_ITEMS_MULTIPLE_DND',
  REMOVE_JOTS_DOCUMENT: 'REMOVE_JOTS_DOCUMENT',
  REORDER_JOTS_NODES: 'REORDER_JOTS_NODES',
  JOTS_TOGGLE_REORDER: 'JOTS_TOGGLE_REORDER',
  DELETE_JOTS_DOCUMENT_ITEMS: 'DELETE_JOTS_DOCUMENT_ITEMS',
  UPDATE_SELECTED_JOTS: 'UPDATE_SELECTED_JOTS',
  UPDATE_MULTIPLE_JOTS_ITEM_DELETE: 'UPDATE_MULTIPLE_JOTS_ITEM_DELETE',
  UPDATE_DELETE_JOTS_ITEM_MODAL: 'UPDATE_DELETE_JOTS_ITEM_MODAL',
  UPDATE_SELECTED_JOTS_DOCUMENT_ITEM: 'UPDATE_SELECTED_JOTS_DOCUMENT_ITEM',
  UPDATE_MULTIPLE_JOTS_DOCUMENT_ITEM_DELETE:
    'UPDATE_MULTIPLE_JOTS_DOCUMENT_ITEM_DELETE',
  REORDER_JOTS_PROJECT_ITEM: 'REORDER_JOTS_PROJECT_ITEM',
  JOTS_DELETE_MODAL: 'JOTS_DELETE_MODAL',
  RESET_JOTS_DELETE_MODAL: 'RESET_JOTS_DELETE_MODAL',
  UPDATE_JOTS_CONTACT_ITEM_SUCCESS: 'UPDATE_JOTS_CONTACT_ITEM_SUCCESS',
  UPDATE_JOTS_DOCUMENT_CONTACT_ITEM_SUCCESS:
    'UPDATE_JOTS_DOCUMENT_CONTACT_ITEM_SUCCESS',
  GET_JOTS_CONTAINER_ITEMS: 'GET_JOTS_CONTAINER_ITEMS',
  GET_JOTS_CONTAINER_ITEMS_SUCCESS: 'GET_JOTS_CONTAINER_ITEMS_SUCCESS',
  GET_JOTS_ARCHIVED_LIST: 'GET_JOTS_ARCHIVED_LIST',
  GET_JOTS_ARCHIVED_LIST_SUCCESS: 'GET_JOTS_ARCHIVED_LIST_SUCCESS',
  RESET_JOTS_ARCHIVED_LIST: 'RESET_JOTS_ARCHIVED_LIST',
  JOTS_CONTAINER_UN_ARCHIVE: 'JOTS_CONTAINER_UN_ARCHIVE',
  JOTS_DELETE_ARCHIVED_PAGE: 'JOTS_DELETE_ARCHIVED_PAGE',
  GET_JOTS_PAGINATION_LIST: 'GET_JOTS_PAGINATION_LIST',
  GET_JOTS_PAGINATION_LIST_SUCCESS: 'GET_JOTS_PAGINATION_LIST_SUCCESS',
  GET_JOTS_PAGINATION_LIST_FAILURE: 'GET_JOTS_PAGINATION_LIST_FAILURE',
  JOTS_MULTI_SELECT: 'JOTS_MULTI_SELECT',
  RESET_JOTS_MULTI_SELECT: 'RESET_JOTS_MULTI_SELECT',
  DELETE_JOTS_ITEM: 'DELETE_JOTS_ITEM',
  DELETE_JOTS_DOCUMENT_MULTIPLE_ITEMS: 'DELETE_JOTS_DOCUMENT_MULTIPLE_ITEMS',
  TOGGLE_JOTS_NODE_GRID_SIZE: 'TOGGLE_JOTS_NODE_GRID_SIZE',
  SET_JOTS_FREE_SPACE_GRID_SIZE: 'SET_JOTS_FREE_SPACE_GRID_SIZE',
  // TODO: Ticket-SP1XD-2383 - DND V2
  REMOVE_JOTS_DRAG_ITEM: 'REMOVE_JOTS_DRAG_ITEM',
  ADD_DRAG_ITEM_TO_JOTS: 'ADD_DRAG_ITEM_TO_JOTS',
  JOTS_FREE_SPACE_COORDINATES: 'JOTS_FREE_SPACE_COORDINATES',
  REORDER_JOTS: 'REORDER_JOTS',
  REORDER_JOTS_DOCUMENT_ITEM: 'REORDER_JOTS_DOCUMENT_ITEM',
  REMOVE_MULTIPLE_ITEM_FROM_JOTS: 'REMOVE_MULTIPLE_ITEM_FROM_JOTS',
  UPDATE_JOTS_MULTI_SELECT_FROM_DND: 'UPDATE_JOTS_MULTI_SELECT_FROM_DND',
  ADD_ITEMS_TO_JOTS_CONTAINER: 'ADD_ITEMS_TO_JOTS_CONTAINER',
  RESET_JOTS_CREATED_PAGE_ID: 'RESET_JOTS_CREATED_PAGE_ID',
  JOTS_DELETE_OBJECT: 'JOTS_DELETE_OBJECT',
  ADD_COPIED_ITEM_TO_JOTS_DOCUMENT: 'ADD_COPIED_ITEM_TO_JOTS_DOCUMENT',
  JOTS_SEARCH_PAGE_ID: 'JOTS_SEARCH_PAGE_ID',
  DELETE_JOTS_ITEM_VIA_PUSHER: 'DELETE_JOTS_ITEM_VIA_PUSHER',
  UPDATE_JOTS_ITEM_SIZE: 'UPDATE_JOTS_ITEM_SIZE',
  UPDATE_JOTS_LAST_ITEM_PROPERTIES: 'UPDATE_JOTS_LAST_ITEM_PROPERTIES',
  CREATE_CONTAINER:'CREATE_CONTAINER',
  SHOW_CONTAINER_PROPERTY_MODAL:'SHOW_CONTAINER_PROPERTY_MODAL',
  CLOSE_JOTS_CONTAINER_MODAL:'CLOSE_JOTS_CONTAINER_MODAL',
  UPDATE_CONTAINER:'UPDATE_CONTAINER',
  UPDATE_JOTS_CONTAINER_PROPERTIES:'UPDATE_JOTS_CONTAINER_PROPERTIES',
  RESET_JOTS_BREADCRUMB: 'RESET_JOTS_BREADCRUMB',
  UPDATE_JOTS_CONTAINER_DROPDOWN:'UPDATE_JOTS_CONTAINER_DROPDOWN',
  UPDATE_JOTS_CONTAINER_DROPDOWN_FOR_MULTISELECT:'UPDATE_JOTS_CONTAINER_DROPDOWN_FOR_MULTISELECT',
  DELETE_JOTS_CONTAINER_DROPDOWN_ITEM:'DELETE_JOTS_CONTAINER_DROPDOWN_ITEM',
};

export default actions;
