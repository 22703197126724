import { store } from 'redux/store';
import authActions from 'redux/auth/actions';
import Pusher from 'pusher-js';
import {
  capturesHelper,
  commonTypeHelper,
  duplicateHelper,
  endSession,
  hierarchyTypeHelper,
  jotsHelper,
  myFilesHelper,
  peoplesHelper,
  shareHelper,
  storageHelper,
  trashHelper,
  updateUserInfo,
} from './pusherHelper';

let pusherConfig = null;

function configPusher(userId) {
  pusherConfig = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  });
  const channel = pusherConfig.subscribe(`App.User.${userId}`);
  pusherEventTypes.map(({ type, action }) =>
    channel.bind(type, (data) => {
      if (data.status === 200) {
        action(data);
      }
    }),
  );
}
export const initiatePusherService = (userId: string = ''): void => {
  if (!pusherConfig) configPusher(userId);
  pusherConfig.connection.bind('connected', () => {
    store.dispatch({
      type: authActions.SET_SOCKET_ID,
      payload: pusherConfig.connection.socket_id,
    });
  });
};

export const destroyPusherConnection = () => {
  if (pusherConfig) pusherConfig.disconnect();
};

const pusherEventTypes = [
  {
    type: 'category_type',
    action: myFilesHelper,
  },
  {
    type: 'common_type',
    action: commonTypeHelper,
  },
  {
    type: 'capture_type',
    action: capturesHelper,
  },
  {
    type: 'jots_type',
    action: jotsHelper,
  },
  {
    type: 'signature_type',
    action: peoplesHelper,
  },
  {
    type: 'trash_type',
    action: trashHelper,
  },
  {
    type: 'sharing_type',
    action: shareHelper,
  },
  {
    type: 'duplication_progress',
    action: duplicateHelper,
  },
  {
    type: 'hierarchy_type',
    action: hierarchyTypeHelper,
  },
  {
    type: 'user_info_type',
    action: updateUserInfo,
  },
  {
    type: 'account_status',
    action: endSession,
  },
  {
    type: 'storage_type',
    action: storageHelper,
  },
];
