import React from 'react';
import {ReactComponent as NewJotsIcon} from 'images/svgs/jots-icon.svg';
import { ReactComponent as CapturesIcon } from 'images/svgs/header/iconCaptures.svg';
import { ReactComponent as MyFilesIcon } from 'images/svgs/header/iconMyFiles.svg';
import { ReactComponent as SharedLinksIcon } from 'images/svgs/header/iconSharedLinks.svg';
import { ReactComponent as SelectAllIcon } from 'images/svgs/select-all.svg';
import { ReactComponent as DeleteWhiteIcon } from 'images/svgs/delete-white.svg';
import { ReactComponent as MoveIcon } from 'images/svgs/moveIcon.svg';
import { ReactComponent as DownloadIcon } from 'images/svgs/downloadIcon.svg';
import { ReactComponent as SearchPanelIcon } from 'images/svgs/searchPanelIcon.svg';
import { faqURL, helpCenterURL, knowledgeBaseURL } from '../constants';
import helpCenter from 'images/svgs/help-center.svg';
import knowledge from 'images/svgs/knowledge-base.svg';
import faq from 'images/svgs/faqs.svg';

export const headerTabIcons = [
  {
    activeTab: 'home',
    image: (
      <NewJotsIcon
        alt={'home'}
        id={'home'}
        width={'18'}
        height={'18'}
        data-tab={'home'}
      />
    ), //this data-tab value has to be as it is in the activeTab
    tooltip: 'tt_home',
    to: '/app',
    tabName: 'home',
    key: 'home',
  },
  {
    activeTab: 'capture',
    image: (
      <CapturesIcon
        alt={'captures'}
        id={'capture'}
        width={'18'}
        height={'18'}
        data-tab={'capture'} //this data-tab value has to be as it is in the activeTab
      />
    ),
    tooltip: 'tt_unsorted',
    to: '/app',
    tabName: 'captures',
    key: '1',
  },
  {
    activeTab: 'activities',
    image: (
      <MyFilesIcon
        alt={'activities'}
        id={'activities'}
        width={'18'}
        height={'18'}
        data-tab={'activities'} //this data-tab value has to be as it is in the activeTab
      />
    ),
    tooltip: 'tt_boards',
    to: '/app',
    tabName: 'my_files',
    key: '2',
  },
  {
    activeTab: 'shared',
    image: (
      <SharedLinksIcon
        alt={'sparks'}
        id={'shared'}
        width={'18'}
        height={'18'}
        data-tab={'shared'} //this data-tab value has to be as it is in the activeTab
      />
    ),
    tooltip: 'tt_sparks',
    to: '/app/shared',
    tabName: 'shared_links',
    key: '4',
  },
  {
    activeTab: 'search',
    image: (
      <SearchPanelIcon
        alt={'search'}
        id={'search'}
        width={'18'}
        height={'18'}
        data-tab={'search'} //this data-tab value has to be as it is in the activeTab
      />
    ),
    tooltip: 'tt_search',
    to: '/app/search',
    tabName: 'search',
    key: '5',
  },
  //Todo: People tab needs to be moved to the context menu of the avatar dropdown
  // {
  //   activeTab: 'people',
  //   image: (
  //     <PeopleLogo alt={'people'} id={'people'} width={'18'} height={'18'} />
  //   ),
  //   tooltip: 'tt_people',
  //   to: '/app/people',
  //   tabName: 'people',
  //   key: '5',
  // },
  // {
  //   activeTab: 'trash',
  //   image: (
  //     <TrashIcon alt={'trash'} id={'trash'} width={'18'} height={'18'} data-tab={'trash'}/> //this data-tab value has to be as it is in the activeTab
  //   ),
  //   tooltip: 'tt_trash',
  //   to: '/app/trash',
  //   tabName: 'trash',
  //   key: '7',
  // },
];

export const entryPanelButtons = [
  {
    translate: 'jot_some_thoughts',
    to: '/app',
    value: 'home',
  },
  {
    translate: 'add_new_content',
    to: '/app',
    value: 'capture',
  },
  {
    translate: 'organize_captures',
    to: '/app',
    value: ['home', 'capture', 'activities'],
  },
  {
    translate: 'organize_boards_folders',
    to: '/app',
    value: 'activities',
  },
  {
    translate: 'reuse_a_Spark',
    to: '/app/shared',
    value: 'shared',
  },
  {
    translate: 're_invoke_tour',
    to: '/app',
    value: ['home', 'capture', 'activities'],
    module: 'tour',
  },
];

export const supportDatas = [
  {
    key: 6,
    name: 'help_center',
    url: helpCenterURL,
    src: helpCenter,
    alt: 'help-center',
  },
  {
    key: 7,
    name: 'knowledge_base',
    url: knowledgeBaseURL,
    src: knowledge,
    alt: 'knowledge-bases',
  },
  {
    key: 8,
    name: 'faq_title',
    url: faqURL,
    src: faq,
    alt: 'FAQs',
  },
];

export const folderCommonActions = [
  {
    icon: <SelectAllIcon alt={'selectAllIcon'} />,
    info: 'select_all',
    updatedInfo: 'unselect_all',
    tooltip: 'tt_select_all_items',
    updatedTooltip: 'tt_unselect_all_items',
  },
  {
    icon: <DownloadIcon alt={'downloadIcon'} />,
    info: 'download',
    tooltip: 'tt_download',
  },
  { icon: <MoveIcon alt={'moveIcon'} />, info: 'move', tooltip: 'tt_move' },
  {
    icon: <DeleteWhiteIcon alt={'deleteIcon'} />,
    info: 'delete',
    tooltip: 'tt_delete',
  },
];

export const alertComponents = {
  boards: 'folderModalMount',
  jots: 'homeFolderModalMount',
  captures: 'captureModalMount',
};

export const trashPaginationCount = 20;

export const sharePaginationCount = 20;

export const capturePaginationCount = 10;

export const peoplePaginationCount = 20;

export const jotsPaginationLimit = 20;

export const myFilesPaginationLimit = 20;

export const trashPaginationLimit = 20;

export const itemTypeAllowedForDownload = [
  'image',
  'video',
  'unknown',
  'audio',
  'note',
];
export const itemTypeAllowedForContainer = [
  'image',
  'video',
  'unknown',
  'audio',
  'note',
  'url',
  'contact',
  'location',
];
export const defaultListApi = 'v4/hierarchy/list/data';
export const updateApi = 'v4/hierarchy/update/data';

export const createApi = 'v4/hierarchy/create/data';