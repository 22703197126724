import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { getRequest, postRequest } from 'utility/axiosClient';
import { handleException } from 'utility/helpers';
import { cloneDeep } from 'lodash';
import { store } from '../store';
import settings from 'config/settings';

export function* getPlansList(params) {
  try {
    // const response = yield call(() => getRequest('list-plans'));
    yield put({
      type: actions.GET_PLANS_LIST_SUCCESS,
      payload: null,
    });
  } catch (error) {
    if (error?.response?.status !== 401) {
      yield put({ type: actions.GET_PLANS_LIST_FAILURE });
    }
    handleException(error, params);
  }
}

export function* checkoutSelectedPlan(params) {
  const { payload } = params;
  try {
    let response;
    if (payload?.planNature === 'free_purchase' || payload?.planId === 'sp_free') {
      const requestPayload = payload?.hasDiscount
        ? { platform: 'web', with_discount: true }
        : { platform: 'web' };

      response = yield call(() =>
        postRequest(`plan/${payload?.planId}/checkout`, requestPayload),
      );
      window.location.href = `${
        settings.REACT_APP_WEB_APP_URL
      }settings/plans?platform=web&purchase_status=${
        response?.message || 'success'
      }`;
    } else if (payload?.planNature === 'addon_purchase') {
      response = yield call(() =>
        postRequest(`plan/${payload?.planId}/update`, {
          platform: 'web',
        }),
      );
      window.location.href = response.data.hosted_page_url;
    } else {
      const requestPayload = payload?.hasDiscount
        ? { platform: 'web', with_discount: true }
        : { platform: 'web' };
      response = yield call(() =>
        postRequest(`chargebee/plan/${payload?.planId}/checkout`, requestPayload),
      );
      window.location.href = response.data.hosted_page_url;
    }
  } catch (error) {
    handleException(error, params);
  }
}

export function* editPlan(params) {
  try {
    const response = yield call(() =>
      postRequest('chargebee/payment-portal', { platform: 'web' }),
    );
    window.location.href = response.data.url;
  } catch (error) {
    yield put({ type: actions.EDIT_PLAN_FAILURE });
    handleException(error, params);
  }
}

export function* cancelPlan(params) {
  try {
    const response = yield call(() =>
      postRequest('chargebee/cancel-subscription', {
        platform: 'web',
      }),
    );
    window.location.href = response.data.url;
  } catch (error) {
    handleException(error, params);
  }
}

export function* getPayNowUrl(params) {
  try {
    const response = yield call(() =>
      getRequest('chargebee/payment-sources?platform=web'),
    );
    window.location.href = response.data.url;
  } catch (error) {
    handleException(error, params);
  }
}

export function* getCollectNowUrl(params) {
  try {
    const response = yield call(() =>
      getRequest('chargebee/collect-now?platform=web'),
    );
    window.location.href = response.data.url;
  } catch (error) {
    handleException(error, params);
  }
}

export function* getPlanDetail(params) {
  try {
    const response = yield call(() => getRequest('users/usage'));
    yield put({
      type: actions.GET_PLAN_DETAIL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    if (error?.response?.status !== 401) {
      yield put({ type: actions.GET_PLAN_DETAIL_FAILURE });
    }
    handleException(error, params);
  }
}

export function* cancelScheduledChanged(params) {
  try {
    const response = yield call(
      () => postRequest('chargebee/cancel-scheduled-changes'),
      { platform: 'web' },
    );
    window.location.href = response.data.url;
  } catch (error) {
    handleException(error, params);
  }
}

export function* getClientPortalUrl(params) {
  try {
    const response = yield call(() => getRequest('get_client_portal_url'));
    yield put({
      type: actions.GET_CLIENT_PORTAL_URL_SUCCESS,
      payload: response?.data?.client_portal_url,
    });
    window.location.href = response?.data?.client_portal_url;
  } catch (error) {
    handleException(error, params);
  }
}

export function* getCancelSubscriptionUrl(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `chargebee/plan/${params?.payload}/get_url_for_plan_cancellation`,
      ),
    );
    yield put({
      type: actions.GET_CANCEL_SUBSCRIPTION_URL_SUCCESS,
      payload: response?.data,
    });
    const token = cloneDeep(store.getState().Auth.token);
    window.location.href = `${response?.data}?token=${token}&platform=web`;
  } catch (error) {
    handleException(error, params);
  }
}

export function* getRemoveCancellationUrl(params) {
  try {
    const response = yield call(() =>
      getRequest('chargebee/plan/get_url_for_remove_plan_cancellation'),
    );
    yield put({
      type: actions.GET_REMOVE_CANCELLATION_URL_SUCCESS,
      payload: response?.data,
    });
    const token = cloneDeep(store.getState().Auth.token);
    window.location.href = `${response?.data}?token=${token}&platform=web`;
  } catch (error) {
    handleException(error, params);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PLANS_LIST, getPlansList),
    takeEvery(actions.SELECT_PLAN, checkoutSelectedPlan),
    takeEvery(actions.EDIT_PLAN, editPlan),
    takeEvery(actions.CANCEL_PLAN, cancelPlan),
    takeEvery(actions.GET_PAY_NOW_URL, getPayNowUrl),
    takeEvery(actions.GET_COLLECT_NOW_URL, getCollectNowUrl),
    takeEvery(actions.GET_PLAN_DETAIL, getPlanDetail),
    takeEvery(actions.CANCEL_SCHEDULED_CHANGES, cancelScheduledChanged),
    takeEvery(actions.GET_CLIENT_PORTAL_URL, getClientPortalUrl),
    takeEvery(actions.GET_CANCEL_SUBSCRIPTION_URL, getCancelSubscriptionUrl),
    takeEvery(actions.GET_REMOVE_CANCELLATION_URL, getRemoveCancellationUrl),
  ]);
}
